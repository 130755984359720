
import { LitElement, html, css } from 'lit-element';
import $ from 'jquery';

export class DynamicValidityWidget extends LitElement {

    static get properties() {
        return {
            validityTypeRadioSelector: { type: String },
            dynamicValidityStartPostponementInputSelector: { type: String },
            dynamicValidityPeriodInputSelector: { type: String },
            fixedValidityInputSelector: { type: String },
            fixedValidityStartInputSelector: { type: String },
            fixedValidityEndInputSelector: { type: String },
            latestPurchaseDateInputSelector: { type: String }
        };
    }

    constructor() {
        super();

        this.validityTypeRadioSelector = "";
        this.dynamicValidityStartPostponementInputSelector = "";
        this.dynamicValidityPeriodInputSelector = "";
        this.fixedValidityInputSelector = "";
        this.fixedValidityStartInputSelector = "";
        this.fixedValidityEndInputSelector = "";
        this.latestPurchaseDateInputSelector = "";

        this.validityType = "Dynamic";
        this.fixedValidityStartDate = null;
        this.fixedValidityEndDate = null;
        this.dynamicValidityPostponement = 6;
        this.dynamicValidityPeriod = 12;
        this.latestPurchaseDate = null;

        this.purchaseTimeframePercentage = 0;
        this.startSlideX = false;
        this.startSlidePercentage = 0;
        this.scheduleMovement = null;
    }

    firstUpdated() {
        let me = this;

        this.validityType = $(this.validityTypeRadioSelector + ":checked").val();
        this.adaptInputsVisibility();
        
        this.dynamicValidityPostponement = parseInt($(this.dynamicValidityStartPostponementInputSelector).val());
        
        this.dynamicValidityPeriod = parseInt($(this.dynamicValidityPeriodInputSelector).val());

        setTimeout(() => {
            let selectedDates = [];
            let fixedValidityStartTimestamp = Date.parse($(this.fixedValidityStartInputSelector).val());
            let fixedValidityEndTimestamp = Date.parse($(this.fixedValidityEndInputSelector).val());

            if (!isNaN(fixedValidityStartTimestamp)) {
                this.fixedValidityStartDate = new Date(fixedValidityStartTimestamp);
                selectedDates.push(this.fixedValidityStartDate);
            }

            if (!isNaN(fixedValidityEndTimestamp)) {
                this.fixedValidityEndDate = new Date(fixedValidityEndTimestamp);
                selectedDates.push(this.fixedValidityEndDate);
            }

            $(this.fixedValidityInputSelector)[0]._flatpickr.setDate(selectedDates);
            me.requestUpdate();
        }, 100);

        let latestPurchaseDateTimestamp = Date.parse($(this.latestPurchaseDateInputSelector).val());
        if (!isNaN(latestPurchaseDateTimestamp)) {
            this.latestPurchaseDate = new Date(latestPurchaseDateTimestamp);
        }

        $(this.validityTypeRadioSelector).change(function(e) {
            me.validityType = $(this).val();
            me.adaptInputsVisibility();
            me.requestUpdate();
        });

        $(this.fixedValidityInputSelector).on("rangechange", function(e) {
            me.fixedValidityStartDate = e.detail.selectedDates[0] ? e.detail.selectedDates[0] : null;
            me.fixedValidityEndDate = e.detail.selectedDates[1] ? e.detail.selectedDates[1] : null;

            if (me.fixedValidityStartDate !== null && me.fixedValidityEndDate !== null) {
                let dates = $(me.fixedValidityInputSelector).val().split(" - ");

                $(me.fixedValidityStartInputSelector).val(dates[0]);
                $(me.fixedValidityEndInputSelector).val(dates[1]);
            }

            me.requestUpdate();
        });

        $(this.dynamicValidityStartPostponementInputSelector).change(function(e) {
            me.dynamicValidityPostponement = parseInt($(this).val());
            me.requestUpdate();
        });

        $(this.dynamicValidityPeriodInputSelector).change(function(e) {
            me.dynamicValidityPeriod = parseInt($(this).val());
            me.requestUpdate();
        });

        $(this.latestPurchaseDateInputSelector).change(function(e) {
            let date = new Date($(this).val());
            me.latestPurchaseDate = date == "Invalid Date" ? null : date;
            me.requestUpdate();
        });

        document.addEventListener("mouseup", e => this.handleMouseUp(e));
        document.addEventListener("mousemove", e => this.handleMouseMove(e));

        this.requestUpdate();
    }

    render() {
        return html`
            <link href="/node_modules/bootstrap/dist/css/bootstrap.min.css" rel="stylesheet" />
            <div class="title">${ss.i18n._t('Form.VoucherValidityField.WIDGET_CAPTION')}:</div>
            <div class="status">
                <div class="progress">
                    <div class="progress-bar" style="width: ${this.purchaseTimeframePercentage}%" role="progressbar" aria-valuenow="${this.purchaseTimeframePercentage}" aria-valuemin="0"
                        aria-valuemax="100">
                        <div class="slider" @mousedown="${this.handleSliderMouseDown}"></div>    
                    </div>
                </div>
            </div>
            <div class="info">${ss.i18n._t('Form.VoucherValidityField.WIDGET_DATE_OF_PURCHASE')}: ${this.purchaseDate().toLocaleDateString()}</div>
            ${this.infoMissing() ? 
                html`<div class="example error">${this.infoMissing()}</div>` : 
                html`
                    <div class="example">
                        ${ss.i18n._t('Form.VoucherValidityField.WIDGET_VOUCHER_VALID_FROM')} <b>${this.validFrom().toLocaleDateString()}</b> ${ss.i18n._t('Form.VoucherValidityField.WIDGET_TO')} <b>${this.validTo().toLocaleDateString()}</b><br />
                        ${ss.i18n._t('Form.VoucherValidityField.WIDGET_VOUCHER_BUYABLE_FROM')} <b>${this.purchaseStart().toLocaleDateString()}</b> ${ss.i18n._t('Form.VoucherValidityField.WIDGET_TO')} <b>${this.purchaseEnd().toLocaleDateString()}</b>
                    </div>
                `
            } 
        `;
    }

    adaptInputsVisibility() {
        if (this.validityType === "Dynamic") {
            this.adaptVisibilityAndRequirability(this.fixedValidityInputSelector, false, false);

            this.adaptVisibilityAndRequirability(this.dynamicValidityStartPostponementInputSelector, true, true);
            this.adaptVisibilityAndRequirability(this.dynamicValidityPeriodInputSelector, true, true);
            this.adaptVisibilityAndRequirability(this.latestPurchaseDateInputSelector, true, true);
        }
        else {
            this.adaptVisibilityAndRequirability(this.fixedValidityInputSelector, true, true);

            this.adaptVisibilityAndRequirability(this.dynamicValidityStartPostponementInputSelector, false, false);
            this.adaptVisibilityAndRequirability(this.dynamicValidityPeriodInputSelector, false, false);
            this.adaptVisibilityAndRequirability(this.latestPurchaseDateInputSelector, false, false);
        }
    }

    adaptVisibilityAndRequirability(fieldSelector, visible, required) {
        let field = $(fieldSelector);
        let fieldWrapper = field.closest(".form-group");

        if (field.length && fieldWrapper.length) {
            if (visible) {
                fieldWrapper.show();
            }
            else {
                fieldWrapper.hide();
            }

            if (required) {
                fieldWrapper.addClass("required");
                field.attr("required", "required");

                if (field[0]._flatpickr !== undefined) {
                    field.next("input").attr("required", "required");
                }
            }
            else {
                fieldWrapper.removeClass("required");
                field.attr("required", false);

                if (field[0]._flatpickr !== undefined) {
                    field.next("input").attr("required", false);
                }
            }
        }
    }

    infoMissing() {
        if (this.validityType === "Dynamic") {
            if (this.latestPurchaseDate === null) {
                return "Choose latest purchase date";
            }
        }
        else {
            if (this.fixedValidityStartDate === null || this.fixedValidityEndDate === null) {
                return "Select validity timespan";
            }
        }

        return false;
    }

    getNumberOfPurchaseDays() {
        let today = new Date();
        let diffTime = Math.abs(this.purchaseEnd() - today);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        return diffDays;
    }

    purchaseDate() {
        let purchaseDate = new Date();
        let daysToAdd = Math.round(this.getNumberOfPurchaseDays() / 100 * this.purchaseTimeframePercentage);
        purchaseDate.setDate(purchaseDate.getDate() + daysToAdd);

        return purchaseDate;
    }

    purchaseStart() {
        return new Date();
    }

    purchaseEnd() {
        let purchaseEnd = this.purchaseStart();

        if (this.validityType === "Dynamic") {
            purchaseEnd = this.latestPurchaseDate;
        }
        else {
            purchaseEnd = new Date(this.fixedValidityStartDate);
            purchaseEnd.setDate(purchaseEnd.getDate() - 1);
        }

        return purchaseEnd;
    }

    validFrom() {
        let validFrom;

        if (this.validityType === "Dynamic") {
            validFrom = this.purchaseDate();
            validFrom.setMonth(validFrom.getMonth() + this.dynamicValidityPostponement);
        }
        else { 
            validFrom = new Date(this.fixedValidityStartDate);
        }

        return validFrom;
    }

    validTo() {
        let validTo;

        if (this.validityType === "Dynamic") {
            validTo = new Date(this.validFrom());
            validTo.setMonth(validTo.getMonth() + this.dynamicValidityPeriod);
        }
        else {
            validTo = new Date(this.fixedValidityEndDate);
        }

        return validTo;
    }

    handleSliderMouseDown(e) {
        this.startSlideX = e.clientX;
        this.startSlidePercentage = this.purchaseTimeframePercentage;
    }

    handleMouseUp(e) {
        this.startSlideX = false;
    }

    handleMouseMove(e) {
        if (this.startSlideX !== false) {
            let progressBarWidth = this.shadowRoot.querySelector(".progress").getBoundingClientRect().width;
            let delta = e.clientX - this.startSlideX;
            let deltaPercentage = Math.round(delta / progressBarWidth * 100);

            let currentSlidePercentage = Math.max(0, Math.min(100, this.startSlidePercentage + deltaPercentage));
            
            this.purchaseTimeframePercentage = currentSlidePercentage;
            this.requestUpdate();
        }
    }

    static get styles() {
        return css`
            .status {
                margin: 5px 10px 5px 10px;
            }

            .progress {
                overflow: visible;
                background: var(--light-gray);
                border: 1px solid var(--gray);
            }

            .progress-bar {
                border-radius: .25rem;
                position: relative;
                overflow: visible;
                transition: 0s all;
                background-color: #74be1d;
            }

            .slider {
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #b8b8b8;
                right: -10px;
                cursor: e-resize;
                border: 1px solid #000000;
                user-select: none;
            }

            .info {
                color: #74be1d;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .addition {
                color: #74be1d;
                font-weight: bold;
            }

            .example.error {
                color: #dc3545;
                font-weight: bold;
            }
        `;
    }
}

customElements.define('dynamic-validity-widget', DynamicValidityWidget);