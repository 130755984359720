import $ from 'jquery';
import popover from 'bootstrap';
import selectpicker from 'bootstrap-select';
import switchButton from 'bootstrap-switch-button';
import flatpickr from "flatpickr";
import { Slovak as flatpickr_sk } from "flatpickr/dist/l10n/sk.js"
import { German as flatpickr_de } from "flatpickr/dist/l10n/de.js"
import { english as flatpickr_en } from "flatpickr/dist/l10n/default.js"
import Dropzone from 'dropzone';
import '../webcomponents/autocomplete-input';
import '../webcomponents/google-places-input';
import '../webcomponents/google-places-address-input';
import '../webcomponents/dynamic-validity-widget';

window.currentLang = function() {
    let map = new Map();
    map.set("en_US", "en");
    map.set("de_DE", "de");
    map.set("sk_SK", "sk");

    if (map.has(ss.i18n.currentLocale)) {
        return map.get(ss.i18n.currentLocale);
    }

    return "en";
}

$(function () {
    const flatpickr_locale = function() {
        let map = new Map();
        map.set("en", flatpickr_en);
        map.set("de", flatpickr_de);
        map.set("sk", flatpickr_sk);

        let locale = flatpickr_en;

        if (map.has(window.currentLang())) {
            locale = map.get(window.currentLang());
        }

        locale.rangeSeparator = " - ";

        return locale;
    }

    $(document).ready(function () {
        //add transition to loading animation
        $('.loading-animation').addClass("transition");

        $('.bootstrap-select').selectpicker({
            // hideDisabled: true
        });

        $('input[type="date"]:not([data-range="true"])').each(function (index, input) {
            flatpickr(input, {
                minDate: $(input).attr("min"),
                maxDate: $(input).attr("max"),
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                locale: flatpickr_locale(),
            });
        });

        $('input[type="date"][data-range="true"]').each(function (index, input) {
            flatpickr(input, {
                minDate: $(input).attr("min"),
                maxDate: $(input).attr("max"),
                mode: "range",
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                locale: flatpickr_locale(),

                onChange (selectedDates, value, datepicker) {
                    if (selectedDates.length === 2) {
                        let diffTime = Math.abs(selectedDates[1] - selectedDates[0]);
                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                        let addDays = Math.max(0, $(input).attr("data-min-range-size") - diffDays);

                        if (addDays > 0) {
                            selectedDates[1].setDate(selectedDates[1].getDate() + addDays);
                            datepicker.setDate(selectedDates);
                            setTimeout(() => {
                                datepicker.jumpToDate(selectedDates[0]);
                                datepicker.open();
                            });
                        }

                        let rangeChangeEvent = new CustomEvent("rangechange", {
                            detail: {
                                selectedDates: selectedDates
                            }
                        });

                        input.dispatchEvent(rangeChangeEvent);
                    }
                },

                onClose(selectedDates, value, datepicker) {
                    if (selectedDates.length === 1) {
                        let addDays = parseInt($(input).attr("data-min-range-size"));
                        let endDate = new Date(selectedDates[0]);
                        
                        endDate.setDate(endDate.getDate() + addDays);
                        selectedDates.push(endDate);
                        datepicker.setDate(selectedDates);

                        setTimeout(() => {
                            datepicker.jumpToDate(selectedDates[0]);
                            datepicker.open();
                        });
                    }

                    if (selectedDates.length <= 1) {
                        let rangeChangeEvent = new CustomEvent("rangechange", {
                            detail: {
                                selectedDates: selectedDates
                            }
                        });

                        input.dispatchEvent(rangeChangeEvent);
                    }
                }
            });
        });

        setTimeout(() => {
            if (getCookie("technicalCookiesApproval") === null) {
                adaptCookiesPopupModeHeight();
                $(".cookies-disclaimer").removeClass("closed");
            }
        }, 2000);

        initLanguageDropdowns();
        initThemeDropdown();
        initDropzoneFields();
        initPopovers();
        initSwitchButtons();
        initVouchersShowMoreButtons();
        initAutocompleteFields();
        initVoucherCalculators();
        animateProgressBars(true);
        defineAnimationMaxHeight();
    });

    $(document).on("submit", "#PopoverLoginForm", function (e) {
        doLogin(e, $(this), result => {
            $(".login-button").popover("hide");
            $(".current-member .name").text(result.member);
            $("#profile-popover").html(result.popover);
            $(".login-button").closest(".nav-item").addClass("d-none");
            $(".current-member").closest(".nav-item").removeClass("d-none");
            $(".sign-up").closest(".nav-item").addClass("d-none");
            $(".log-out").closest(".nav-item").removeClass("d-none");
        });
    });

    $(document).on("submit", ".checkout-login-form", function (e) {
        showAnimation(true, ss.i18n._t('Checkout.NextStep.PROCESSING'));
        doLogin(e, $(this), result => {
            window.location.href = $(this).find("input[name='ForwardURL']").val();
        });
    });

    $(document).on("click touchend", ".sidemenu-toggler", function (e) {
        e.preventDefault();
        $('.popover').popover('hide');
        $("body").toggleClass("show-menu");
    });

    $(document).on("click touchend", ".voucher .teaser .detail .description .show-more", function (e) {
        e.preventDefault();
        $(this).closest(".detail").toggleClass("expanded");
    });

    $(document).on("click touchend", ".voucher .amount .minus", function (e) {
        e.preventDefault();
        let input = $(this).siblings("input");
        let currentVal = isNaN(parseInt(input.val())) ? 0 : parseInt(input.val());
        input.val(Math.max(0, currentVal - 1));
        input.trigger("change");
    });

    $(document).on("click touchend", ".voucher .amount .plus", function (e) {
        e.preventDefault();
        let input = $(this).siblings("input");
        let currentVal = isNaN(parseInt(input.val())) ? 0 : parseInt(input.val());
        input.val(currentVal + 1);
        input.trigger("change");
    });

    $(document).on("change", ".voucher .amount input", function (e) {
        let input = $(this);
        let checkoutFooter = $(".checkout-footer");
        let subtotalPriceSpan = input.closest(".amount-options").find(".price");
        let totalPriceSpan = checkoutFooter.find(".price");
        let value = isNaN(parseInt(input.val())) ? 0 : Math.max(0, parseInt(input.val()));

        input.val(value);

        e.preventDefault();
        input.siblings("button").attr("disabled", true);
        input.attr("readonly", true);

        $.ajax({
            method: "GET",
            url: $(this).attr("data-href"),
            data: {
                amount: value
            },
            dataType: "json",
            success: (result) => {
                if (result && result.status === "ok") {
                    if (result.amount > 0) {
                        let pricePerUnit = parseFloat(subtotalPriceSpan.data("ppu"));
                        let subtotal = result.amount * pricePerUnit;
                        subtotalPriceSpan.text(subtotal.toFixed(2));
                        subtotalPriceSpan.closest(".subtotal").removeClass("d-none");
                    }
                    else {
                        subtotalPriceSpan.closest(".subtotal").addClass("d-none");
                    }

                    let totalPrice = 0;
                    $("*[data-ppu]").each(function () {
                        let amount = Math.max(0, parseInt($(this).closest(".amount-options").find("input").val()));
                        let subtotal = parseFloat($(this).data("ppu")) * amount;
                        totalPrice += subtotal;
                    });

                    totalPriceSpan.text(totalPrice.toFixed(2));

                    if (totalPrice > 0) {
                        checkoutFooter.find(".btn.primary").removeClass("disabled");
                    }
                    else {
                        checkoutFooter.find(".btn.primary").addClass("disabled");
                    }
                }
                else if (result && result.status === "error") {
                    console.error(result.error);
                }
                else {
                    console.error("Error occured. Try again or contact administrator.");
                }
            },
            error: (xhr, status, error) => {
                console.error(error);
            },
            complete: () => {
                input.siblings("button").attr("disabled", false);
                input.attr("readonly", false);
            }
        });
    });

    $(document).on("click touchend", ".load-more input", function (e) {
        e.preventDefault();
        showAnimation(true, ss.i18n._t('SearchResults.LOADING'));

        let form = $(this).closest("form");

        $.ajax({
            method: "GET",
            url: form.attr("action"),
            data: form.serialize(),
            dataType: "html",
            success: (result) => {
                form.closest(".row").append(result);
                form.closest(".col-12").remove();
                showAnimation(false);
            },
            error: (xhr, status, error) => {
                console.error(error);
                showAnimation(false);
            }
        });
    });

    $(document).on("click touchend", ".checkout-footer .guest", function (e) {
        e.preventDefault();
        $(".checkout-footer").addClass("slide-up show-guest");
        $("body").addClass("no-scroll");
    });

    $(document).on("click touchend", ".checkout-footer .login", function (e) {
        e.preventDefault();
        $(".checkout-footer").addClass("slide-up show-login");
        $("body").addClass("no-scroll");
    });

    $(document).on("click touchend", ".checkout-footer .close", function (e) {
        e.preventDefault();
        $(".checkout-footer").removeClass("slide-up show-login show-guest");
        $("body").removeClass("no-scroll");
    });

    $(document).on("click touchend", ".cookies-disclaimer .accept-all", function (e) {
        e.preventDefault();
        updateCookies(true);
    });

    $(document).on("click touchend", ".cookies-disclaimer .save-settings", function (e) {
        e.preventDefault();
        updateCookies(false);
    });

    $(document).on("click touchend", ".open-cookies-settings", function (e) {
        e.preventDefault();
        $("body").toggleClass("no-scroll");
        $(".cookies-disclaimer").removeClass("closed");
        $(".cookies-disclaimer").toggleClass("opened-settings");
    });

    $(document).on("click touchend", ".voucher .conditions .btn", function (e) {
        e.preventDefault();
        $(this).toggleClass("collapsed");
        $(this).closest(".teaser").next(".orders").toggleClass("collapsed");
    });

    $(document).on("click touchend", ".lobooster-list .items li .actions .btn.toggle", function (e) {
        e.preventDefault();

        $(this).toggleClass("collapsed");
        $(this).closest(".teaser").next(".detail").toggleClass("collapsed");
    });

    $(document).on("click touchend", ".show-modal", function (e) {
        e.preventDefault();
        let modalTitle = $(this).attr("data-modal-title");
        let modalBody = "";
        
        if ($(this).attr("data-modal-body")) {
            modalBody = $(this).attr("data-modal-body");
        }
        else {
            modalBody = $($(this).attr("data-modal-html")).find("> *").detach();
            //$('#parentNode').append(element);

            // modalBody = $($(this).attr("data-modal-html")).html();
            // $($(this).attr("data-modal-html")).html("");
        }
        
        let modalWidth = parseInt($(this).attr("data-max-modal-width"));
        showModal(modalTitle, modalBody, modalDialog => {
            if ($(this).attr("data-modal-html")) {
                // $($(this).attr("data-modal-html")).html(modalDialog.find(".modal-body").html());
                let elements = modalDialog.find(".modal-body > *").detach();
                $($(this).attr("data-modal-html")).append(elements);
            }
        }, modalWidth);
    });

    $(document).on("click touchend", ".confirmation", function (e) {
        let confirmationTitle = $(this).attr("data-confirmation-title");
        let confirmationBody = $(this).attr("data-confirmation-body");
        let cancelButton = $(this).attr("data-confirmation-cancel");
        let confirmationButton = $(this).closest("form").length ? $(this).val() : $(this).text();

        let confirmCallback = () => {
            if ($(this).closest("form").length) {
                $(this).closest("form").submit();
            }
            else {
                window.location.href = $(this).attr("href");
            }
        };

        let valid = true;

        if ($(this).closest("form").length) {
            $(this).closest("form").find("input[required], textarea[required]").each(function (i, input) {
                if (!$(input).val() || $(input).val() === "") {
                    valid = false;
                }
            });
        }

        if (valid) {
            e.preventDefault();
            showConfirmation(confirmationTitle, confirmationBody, cancelButton, confirmationButton, confirmCallback);
        }
    });

    $(document).on("click touchend", ".dz-preview.attached .dz-remove", function(e) {
        e.preventDefault();
        $(this).closest(".dropzone-field").find("input[type='hidden']").val("");
        $(this).closest(".dz-preview").remove();
    });

    $(window).scroll(function (e) {
        animateProgressBars();
    });

    $(window).resize(function () {
        adaptCookiesPopupModeHeight();
    });

    var doLogin = function (e, form, successCallback) {
        e.preventDefault();

        var submitButton = form.find("input[type='submit']");

        submitButton.attr("disabled", true);

        $.ajax({
            method: form.attr("method"),
            url: form.attr("action"),
            data: form.serialize(),
            dataType: "json",
            success: (result) => {
                form.find(".errors").html("");
                if (result && result.status === "ok") {
                    form.find(".errors").addClass("d-none");
                    successCallback(result);
                }
                else if (result && result.status === "error") {
                    displayErrors(form, result.errors);
                    showAnimation(false);
                }
                else if (Array.isArray(result)) {
                    displayErrors(form, result);
                    showAnimation(false);
                }
                else {
                    displayErrors(form, [ss.i18n._t('Members.Authentication.ERROR_DURING_LOGIN')]);
                    showAnimation(false);
                }
            },
            error: (xhr, status, error) => {
                displayErrors(form, [ss.i18n._t('Members.Authentication.ERROR_DURING_LOGIN')]);
                showAnimation(false);
            },
            complete: () => {
                submitButton.attr("disabled", false);
            }
        });
    }

    var displayErrors = function (form, errors) {
        form.find(".errors").html("");

        errors.forEach(error => {
            var message = error.message || error;
            form.find(".errors").append("<div class='error'>" + message + "</div>");
        });

        form.find(".errors").removeClass("d-none");
    }

    var animateProgressBars = function (initial) {
        $(".progress-bar:not(.animated)").each(function () {
            if ($(window).scrollTop() + $(window).height() < $(this).offset().top || $(window).scrollTop() > $(this).offset().top + $(this).height()) {
                $(this).css("width", "0");
            }
            else {
                $(this).css("width", $(this).attr("aria-valuenow") + "%");
                $(this).addClass("animated");
            }
        });

        if (initial) {
            $(".progress-bar.animated").each(function () {
                $(this).css("width", $(this).attr("aria-valuenow") + "%");
            });
        }
    }

    var initVoucherCalculators = function() {
        $(".voucher-price-calculator").each(function(index, calculator) {
            let paymentMethodInput = $(calculator).find("input[name='calculator-payment-method']");
            let voucherPriceInput = $(calculator).find("input[name='calculator-voucher-price']");
            let voucherAmountInput = $(calculator).find("input[name='calculator-voucher-amount']");
            
            let loboosterFee = parseFloat($(calculator).attr("data-lobooster-fee"));
            let stripeTransactionFee = parseFloat($(calculator).attr("data-stripe-transaction-fee"));
            let stripeFee = parseFloat(paymentMethodInput.val());

            // set constants
            $(calculator).find(".lobooster-fee").text(loboosterFee);
            $(calculator).find(".stripe-transaction-fee").text(stripeTransactionFee.toFixed(2));
            
            let voucherPrice = parseFloat(voucherPriceInput.val());
            let amount = parseFloat(voucherAmountInput.val());
            recalculateVoucherPrice($(calculator), voucherPrice, amount, loboosterFee, stripeFee, stripeTransactionFee);
            $(calculator).find(".stripe-fee").text(stripeFee);

            paymentMethodInput.on("change", function(e) {
                stripeFee = parseFloat($(this).val());
                $(calculator).find(".stripe-fee").text(stripeFee);
                recalculateVoucherPrice($(calculator), voucherPrice, amount, loboosterFee, stripeFee, stripeTransactionFee);
            });

            voucherAmountInput.on("change keyup", function(e) {
                voucherPrice = parseFloat(voucherPriceInput.val());
                amount = parseFloat(voucherAmountInput.val());

                recalculateVoucherPrice($(calculator), voucherPrice, amount, loboosterFee, stripeFee, stripeTransactionFee);
            });

            voucherPriceInput.on("change keyup", function(e) {
                voucherPrice = parseFloat(voucherPriceInput.val());
                amount = parseFloat(voucherAmountInput.val());

                recalculateVoucherPrice($(calculator), voucherPrice, amount, loboosterFee, stripeFee, stripeTransactionFee);
            });
        });
    }

    var recalculateVoucherPrice = function(calculator, voucherPrice, amount, loboosterFee, stripeFee, stripeTransactionFee) {
        let loboosterCostsPerVoucher = (Math.round((voucherPrice / 100 * loboosterFee + Number.EPSILON) * 100) / 100).toFixed(2);
        let loboosterCosts = (loboosterCostsPerVoucher * amount).toFixed(2);
        
        let brutto = voucherPrice * amount;

        let stripeCosts = brutto / 100 * stripeFee;
        stripeCosts = (Math.round((stripeCosts + Number.EPSILON) * 100) / 100).toFixed(2);

        let netto = brutto - loboosterCosts - stripeCosts - stripeTransactionFee;
        netto = (Math.round((netto + Number.EPSILON) * 100) / 100).toFixed(2);

        if (!isNaN(loboosterCosts) && 
            !isNaN(stripeCosts) &&
            !isNaN(brutto) &&
            !isNaN(netto)) {

            calculator.find(".lobooster-costs").text(loboosterCosts);
            calculator.find(".stripe-costs").text(stripeCosts);
            calculator.find(".brutto").text(brutto.toFixed(2));
            calculator.find(".netto").text(netto);
        }
    }

    var initDropzoneFields = function () {
        Dropzone.autoDiscover = false;

        $(".dropzone-field").each(function(index, field){
            let myDropzone = new Dropzone(field, { 
                url: $(field).closest('form').attr("action") + "/field/" + $(field).find("input[type='hidden']").attr("name") + "/upload",
                uploadMultiple: false,
                parallelUploads: 1,
                maxFiles: 1,
                headers: {
                    'X-Securityid': $(field).closest('form').find("input[name='SecurityID']").val()
                },
                previewTemplate: $("#dropzone-image-template").html(),

                init: function() {
                    this.hiddenFileInput.removeAttribute('multiple');
                }
            });

            myDropzone.on("removedfile", function(file) {
                $(this.element).find("input[type='hidden']").val("");
                $(this.element).removeClass("is-invalid");
            });

            myDropzone.on("success", function(file, response) {
                $(this.element).find("input[type='hidden']").val(response);
            });

            myDropzone.on("error", function(file, errorMessage, xhr) {
                if (xhr) {
                    $(this.element).find("input[type='hidden']").val("");
                    $(this.element).addClass("is-invalid");
                    $(this.element).next(".invalid-feedback").text(errorMessage);
                }
            });

            myDropzone.on("sending", function(xhr, formData) {
                $(this.element).find(".dz-details").removeClass("animate");
                if (!$(this.element).hasClass("dz-max-files-reached")) {
                    $(this.element).closest("form").find("input[type='submit']").addClass("disabled");
                }
            });

            myDropzone.on("complete", function(file, response) {
                setTimeout(() => $(this.element).find(".dz-details").addClass("animate"), 0);
                $(this.element).closest("form").find("input[type='submit']").removeClass("disabled");
            });

            myDropzone.on("maxfilesexceeded", function(file) {
                this.removeFile(file);
            });
        });
    }

    var initPopovers = function () {
        $('[data-toggle="popover"]').popover();
        $(".login-button").popover({
            html: true,
            sanitize: false,
            placement: "bottom",
            content: () => {
                return $('#login-form').html();
            }
        });

        $(".current-member").popover({
            html: true,
            sanitize: false,
            placement: "bottom",
            content: () => {
                return $('#profile-popover').html();
            }
        });
    }

    var initSwitchButtons = function () {
        document.querySelectorAll('input:not([data-ajax="true"])[type="checkbox"][data-toggle="switchbutton"]').forEach(checkbox => checkbox.switchButton());

        $('input[type="checkbox"][data-toggle-ajax="switchbutton"]').each(function(){

            $(this)[0].switchButton({
                onlabel: `<span class="text">${$(this).attr("data-enabledlabel")}</span><span class="processing-text">${$(this).attr("data-processlabel")}</span>`,
                offlabel: `<span class="text">${$(this).attr("data-disabledlabel")}</span><span class="processing-text">${$(this).attr("data-processlabel")}</span>`,
            });

            $(this).change(function() {
                $(this)[0].switchButton("disable");

                $(this).closest(".switch")
                    .addClass("btn-warning");

                $(this).next(".switch-group").find("label")
                    .removeClass("btn-light")
                    .addClass("btn-warning processing");

                $.ajax({
                    method: "GET",
                    url: $(this).attr("data-url"),
                    data: {
                        enabled: $(this).prop('checked') ? 1 : 0
                    },
                    dataType: "json",
                    success: (result) => {
                        $(this)[0].switchButton("enable");
                        if (result && result.status === "ok") {
                            addAutoDismissMessage(result.message, "success", $(this).closest("li"));
                        }
                        else if (result && result.status === "error") {
                            $(this)[0].switchButton($(this).prop('checked') ? "off" : "on", true);
                            addAutoDismissMessage(result.message, "danger", $(this).closest("li"));
                        }
                        else {
                            console.error("Error occured. Try again or contact administrator.");
                        }
                    },
                    error: (xhr, status, error) => {
                        console.error(error);
                        $(this)[0].switchButton("enable");
                        $(this)[0].switchButton($(this).prop('checked') ? "off" : "on", true);
                        addAutoDismissMessage(result.message, "danger", $(this).closest("li"));
                    },
                    complete: () => {
                        $(this).closest(".switch")
                            .removeClass("btn-warning");

                        $(this).next(".switch-group").find("label")
                            .removeClass("btn-warning processing");

                        $(this).next(".switch-group").find(".switch-off")
                            .addClass("btn-light");
                    }
                });
            });
        });
    }

    var addAutoDismissMessage = function(message, severity, appendAfterElement) {
        let icon = severity === "success" ? "lobooster-icon-checked" : "lobooster-icon-warning";
        let id = "id-" + Date.now();
        appendAfterElement.after(`
        <li id="${id}">
            <div class="message ${severity}">
                <span class="icon">
                    <i class="${icon}"></i>
                </span>
                <span class="text">
                    ${message}
                </span>
            </div>
        </li>
        `);

        setTimeout(() => $("#" + id).remove(), 2000);
    }

    var initAutocompleteFields = function () {
        $(".cities-autocomplete").each(function () {
            $(this)[0].setResultToHtmlConverter(i => i);
        });
    }

    var initLanguageDropdowns = function () {
        $("#lang-select").on("changed.bs.select", function(e) {
            window.location.href = $(e.currentTarget).val();
        });

        $("#lang-select-mobile").on("changed.bs.select", function(e) {
            window.location.href = $(e.currentTarget).val();
        });
    }

    var initThemeDropdown = function () {
        let theme = getCookie('theme');
        if (theme && theme === "light" || theme === "dark") {
            $("#theme-select").selectpicker('val', theme);
        }

        $("#theme-select").on("changed.bs.select", function(e) {
            $("body").removeClass("light dark");
            if ($(this).val() === "system") {
                deleteCookie('theme');
            }
            else if ($(this).val() === "light" || $(this).val() === "dark") {
                setCookie('theme', $(this).val());
                $("body").addClass($(this).val());
            }
        });
    }

    var initVouchersShowMoreButtons = function() {
        $(".voucher .teaser .detail .description").each(function() {
            $(this).addClass("auto-height");
            if ($(this).outerHeight() > 43) {
                $(this).find(".show-more").addClass("visible");
            }
            $(this).removeClass("auto-height");
        });
    }

    var defineAnimationMaxHeight = function () {
        setTimeout(() => {
            $(".max-height").each((index, element) => {
                $(element).css("max-height", $(element).height() + "px");
                $(element).addClass("collapsed animated-collapse");
            });
        }, 100);
    }

    var showAnimation = function(show, text) {
        text = text || "";
        if (show) {
            $(".loading-animation").addClass("show");
            $(".loading-animation .text").text(text);
        }
        else {
            $(".loading-animation").removeClass("show");
        }
    }

    var showConfirmation = function (confirmationTitle, confirmationBody, cancelButton, confirmationButton, confirmCallback) {
        let confirmModal = createModalDialog(confirmationTitle, confirmationBody, cancelButton, null, confirmationButton, confirmCallback);
        confirmModal.modal('show');
    }

    var showModal = function (title, body, closeCallback, maxWidth) {
        let confirmModal = createModalDialog(title, body, 'Close', maxWidth);

        confirmModal.on('hide.bs.modal', function () {
            closeCallback(confirmModal);
        });

        confirmModal.modal('show');
    }

    var createModalDialog = function (title, body, closeButtonTitle, maxWidth, primaryActionTitle, primaryActionCallback) {
        let modalDialog = $('#modalDialog');

        modalDialog.find("#modalLabel").text(title);

        if (typeof body === 'string' || body instanceof String) {
            modalDialog.find(".modal-body").html(body);
        }
        else {
            modalDialog.find(".modal-body").empty();
            modalDialog.find(".modal-body").append(body);
        }
        
        modalDialog.find(".modal-footer").empty(); //remove all buttons

        let closeButton = $('<button type="button" class="btn ' + (primaryActionTitle ? "secondary" : "primary") + '" data-dismiss="modal">' + closeButtonTitle + '</button>');
        modalDialog.find(".modal-footer").append(closeButton);

        if (primaryActionTitle && primaryActionCallback) {
            let actionButton = $('<button type="button" class="btn primary">' + primaryActionTitle + '</button>');
            modalDialog.find(".modal-footer").append(actionButton);
    
            actionButton.on("click touchend", function (e) {
                primaryActionCallback();
            });
        }

        if (maxWidth) {
            modalDialog.find(".modal-dialog").css("maxWidth", maxWidth);
        }

        return modalDialog;
    }

    var adaptCookiesPopupModeHeight = function() {
        let disclaimer = $(".cookies-disclaimer").clone();
        let openedSettings = disclaimer.hasClass("opened-settings");

        if (openedSettings) {
            disclaimer.removeClass("opened-settings");
            $("body").removeClass("no-scroll");
        }

        //append to compute width
        $("body").append(disclaimer);

        let popupHeight = $(disclaimer).find(".popup").outerHeight();

        if (openedSettings) {
            $("body").addClass("no-scroll");
        }

        //remove from dom
        disclaimer.remove();

        $(".cookies-disclaimer").css("transform", "translateY(calc(100% - " + popupHeight + "px)");
    }

    var updateCookies = function(acceptAll) {
        let cookiesWhitelist = ['technical', 'analytical', 'preference'];

        //first remove all cookies
        cookiesWhitelist.forEach(cookiesType => {
            deleteCookie(cookiesType + "CookiesApproval");
        });

        $(".cookies-disclaimer input[data-cookies]").each(function() {
            let cookiesType = $(this).attr("data-cookies");
            let accept = acceptAll || $(this).prop("checked");
            let switchButtonState = accept ? "on" : "off";

            if (cookiesType && cookiesWhitelist.includes(cookiesType)) {
                if (accept) {
                    setCookie(cookiesType + "CookiesApproval", true);
                }
                $(this)[0].switchButton(switchButtonState);
            }
        });

        setCookie("technicalCookiesApproval", true);

        setTimeout(() => {
            $(".cookies-disclaimer").addClass("closed");
            $(".cookies-disclaimer").removeClass("opened-settings");
            $("body").removeClass("no-scroll");
        }, 200);
    }

    var getCookie = function(cookieName) {
        return (document.cookie.match(new RegExp("^(?:.*;)?\\s*" + cookieName + "\\s*=\\s*([^;]+)(?:.*)?$"))||[,null])[1];
    }

    var deleteCookie = function(cookieName) {
        let dateInPast = new Date();
        dateInPast.setMonth( dateInPast.getMonth() - 1 );
        document.cookie = cookieName + "=;path=/;expires=" + dateInPast.toUTCString();
    }

    var setCookie = function(cookieName, cookieValue, expireDate) {
        if (!expireDate) {
            expireDate = new Date();
            expireDate.setFullYear(expireDate.getFullYear() + 1 ); 
        }

        document.cookie = cookieName + "=" + cookieValue + ";path=/;expires=" + expireDate.toUTCString();
    }
});