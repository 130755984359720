
import { LitElement, html, css } from 'lit-element';
import { AutocompleteInput } from './autocomplete-input';
import jQuery from 'jquery';

export class GooglePlacesAddressInput extends LitElement {

    static get properties() {
        return {
            inputName: { type: String },
            value: { type: String },
            label: {
                type: String,
                attribute: 'label',
                reflect: true
            },
            message: {
                type: String,
                attribute: 'message',
                reflect: true
            },
            description: {
                type: String,
                attribute: 'description',
                reflect: true
            },
            hasClearButton: { type: Boolean },
            dataProviderUrl: { type: String },
            googlePlaceInputId: { type: String }
        };
    }

    constructor() {
        super();

        this.inputName = "google-places-address-name";
        this.value = "";
        this.label = "";
        this.message = "";
        this.description = "";
        this.hasClearButton = false;
    }

    firstUpdated() {
        this.shadowRoot.querySelector("autocomplete-input").setResultToIdConverter(i => i.place_id);
        
        this.shadowRoot.querySelector("autocomplete-input").setResultToHtmlConverter(i => html`
            <span class="title">${i.structured_formatting.main_text}</span>
            <span class="address">${i.structured_formatting.secondary_text}</span>
        `);
        
        this.shadowRoot.querySelector("autocomplete-input").setSuggestionTitleSelector(".title");
    }

    render() {
        return html`
        <autocomplete-input 
            @suggestion-select="${this.handleSuggestionClick}" 
            @clear-input="${this.handleClearInput}" 
            value="${this.value}" 
            inputId="${this.googlePlaceInputId}-autocomplete" 
            label="${this.label}" 
            message="${this.message}" 
            description="${this.description}" 
            has-clear-button="true" 
            data-provider-url="apis/google/address/autocomplete" 
            powered-by-google="true"></autocomplete-input>`;
    }

    handleClearInput(e) {
        let formId = jQuery("#" + this.googlePlaceInputId).closest("form").attr("id");

        jQuery("#" + formId + "_Street").val("");
        jQuery("#" + formId + "_Street").attr("readonly", false);

        jQuery("#" + formId + "_City").val("");
        jQuery("#" + formId + "_City").attr("readonly", false);

        jQuery("#" + formId + "_ZIP").val("");
        jQuery("#" + formId + "_ZIP").attr("readonly", false);

        // jQuery("#" + formId + "_Country").val("");
        // jQuery("#" + formId + "_Country").selectpicker('refresh');
        // jQuery("#" + formId + "_Country").closest(".form-group").removeClass("readonly");

        jQuery("#" + this.googlePlaceInputId).val("");
        this.requestUpdate();
    }

    handleSuggestionClick(e) {
        jQuery.ajax({
            method: "GET",
            dataType: "json",
            url: "apis/google/address/detail",
            data: {
                place_id: e.detail.selectedId
            },
            success: response => {
                console.log(response);
                if (response.status !== "OK" || !response.result) {
                    if (response.status === "LOBOOSTER_ERROR") {
                        this.showError(response.error_message);
                    }
                    else {
                        this.showError("Cannot fetch data about this address. Please try again, or <a href='mailto:support@lobooster.com'>contact us</a>");
                    }
                    return false;
                }

                if (!response.result.address) {
                    this.showError("Cannot fetch data about this address. Please try again, or <a href='mailto:support@lobooster.com'>contact us</a>");
                    return false;
                }

                let address = response.result.address;
                let street = address.street;
                let city = address.city;
                let zip = address.zip;
                let country_iso = address.country_iso_3166_1_alpha_2;

                let formId = jQuery("#" + this.googlePlaceInputId).closest("form").attr("id");

                jQuery("#" + formId + "_Name").val(name);
                jQuery("#" + formId + "_Name").attr("readonly", true);

                jQuery("#" + formId + "_Street").val(street);
                jQuery("#" + formId + "_Street").attr("readonly", true);

                jQuery("#" + formId + "_City").val(city);
                jQuery("#" + formId + "_City").attr("readonly", true);

                jQuery("#" + formId + "_ZIP").val(zip);
                jQuery("#" + formId + "_ZIP").attr("readonly", true);

                // jQuery("#" + formId + "_Country").val(country_iso);
                // jQuery("#" + formId + "_Country").selectpicker('refresh');
                // jQuery("#" + formId + "_Country").closest(".form-group").addClass("readonly");

                jQuery("#" + this.googlePlaceInputId).val(e.detail.selectedId);
                this.removeError();

                this.requestUpdate();
            }
        });
    }

    showError(message) {
        let autocomplete = this.shadowRoot.querySelector("autocomplete-input");
        autocomplete.setAttribute("message", message);
        autocomplete.clearInput();
    }

    removeError() {
        let autocomplete = this.shadowRoot.querySelector("autocomplete-input");
        autocomplete.setAttribute("message", "");
    }
    
}

customElements.define('google-places-address-input', GooglePlacesAddressInput);