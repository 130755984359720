
import { LitElement, html, css } from 'lit-element';
import { AutocompleteInput } from './autocomplete-input';
import jQuery from 'jquery';

export class GooglePlacesInput extends LitElement {

    static get properties() {
        return {
            inputName: { type: String },
            value: { type: String },
            country: { type: String },
            label: {
                type: String,
                attribute: 'label',
                reflect: true
            },
            message: {
                type: String,
                attribute: 'message',
                reflect: true
            },
            description: {
                type: String,
                attribute: 'description',
                reflect: true
            },
            readonly: { type: Boolean },
            hasClearButton: { type: Boolean },
            dataProviderUrl: { type: String },
            googlePlaceInputId: { type: String }
        };
    }

    constructor() {
        super();

        this.formId = "";
        this.inputName = "google-places-name";
        this.value = "";
        this.label = "";
        this.message = "";
        this.description = "";
        this.hasClearButton = false;
        this.readonly = false;
        this.country = "";
    }

    firstUpdated() {
        this.formId = jQuery("#" + this.googlePlaceInputId).closest("form").attr("id");

        let autocompleteInput = this.shadowRoot.querySelector("autocomplete-input");

        autocompleteInput.setResultToIdConverter(i => i.place_id);
        
        autocompleteInput.setResultToHtmlConverter(i => html`
            <span class="title">${i.structured_formatting.main_text}</span>
            <span class="address">${i.structured_formatting.secondary_text}</span>
        `);
        
        autocompleteInput.setSuggestionTitleSelector(".title");

        jQuery("#" + this.formId + "_GooglePlaceCountry").change(e => {
            let changedValue = jQuery(e.target).val();
            this.handleCountryChange(changedValue, true);
        });

        autocompleteInput.addQueryParamProvider("country", () => this.country);

        if (this.country) {
            this.handleCountryChange(this.country);
        }
    }

    handleCountryChange(newCountry, clearInput) {
        clearInput = clearInput || false;
        let autocompleteInput = this.shadowRoot.querySelector("autocomplete-input");
        
        if (clearInput) {
            autocompleteInput.clearInput(true);
            jQuery("#GooglePlaceCountryGooglePlaceIdGooglePlaceConfirmationEmailGooglePlaceDomain .description").addClass("d-none");
        }

        if (newCountry) {
            this.classList.remove("d-none");
        }
        else {
            this.classList.add("d-none");
        }

        this.country = newCountry;
    }

    render() {
        return html`
        <autocomplete-input 
            @suggestion-select="${this.handleSuggestionClick}" 
            @clear-input="${this.handleClearInput}" 
            value="${this.value}" 
            inputId="${this.googlePlaceInputId}-autocomplete" 
            label="${this.label}" 
            message="${this.message}" 
            description="${this.description}" 
            ?readonly="${this.readonly}"
            has-clear-button="true" 
            last-selection-on-blur="true" 
            data-provider-url="${window.currentLang()}/apis/google/place/autocomplete" 
            powered-by-google="true"></autocomplete-input>`;
    }

    handleClearInput(e) {
        let confirmationEmailField = jQuery("#" + this.formId + "_GooglePlaceConfirmationEmail");
        confirmationEmailField.val("");
        confirmationEmailField.closest(".form-group").addClass("d-none");

        jQuery("#" + this.formId + "_Name").val("");
        jQuery("#" + this.formId + "_Name").attr("readonly", false);

        jQuery("#" + this.formId + "_Street").val("");

        jQuery("#" + this.formId + "_City").val("");

        jQuery("#" + this.formId + "_ZIP").val("");

        jQuery("#" + this.formId + "_Country").val("");
        jQuery("#" + this.formId + "_Country").selectpicker('refresh');

        jQuery("#GooglePlaceCountryGooglePlaceIdGooglePlaceConfirmationEmailGooglePlaceDomain .description").addClass("d-none");
        jQuery("#" + this.formId + "_GooglePlaceConfirmationEmail").val("");
        jQuery("#" + this.formId + "_GooglePlaceDomain").val("");
        jQuery("#" + this.googlePlaceInputId).val("");
        this.requestUpdate();
    }

    handleSuggestionClick(e) {
        jQuery.ajax({
            method: "GET",
            dataType: "json",
            url: `${window.currentLang()}/apis/google/place/detail`,
            data: {
                place_id: e.detail.selectedId
            },
            success: response => {
                if (response.status !== "OK" || !response.result) {
                    if (response.status === "LOBOOSTER_ERROR") {
                        this.showError(response.error_message);
                    }
                    else {
                        this.showError("Cannot fetch data about this Google Place. Please try again, or <a href='mailto:support@lobooster.com'>contact us</a>");
                    }
                    return false;
                }

                if (!response.result.name) {
                    this.showError("Your 'Google Place' account doesn't contain information about your business name.");
                    return false;
                }

                if (!response.result.address) {
                    this.showError("Your 'Google Place' account doesn't contain information about your business address.");
                    return false;
                }

                if (!response.result.website) {
                    this.showError("Your 'Google Place' account doesn't contain information about your business website.");
                    return false;
                }

                let address = response.result.address;
                let name = response.result.name;
                let domain = response.result.domain;
                let street = address.street;
                let city = address.city;
                let zip = address.zip;
                let country_iso = address.country_iso_3166_1_alpha_2;

                jQuery("#" + this.formId + "_Name").val(name);
                jQuery("#" + this.formId + "_Name").attr("readonly", true);

                jQuery("#" + this.formId + "_Street").val(street);

                jQuery("#" + this.formId + "_City").val(city);

                jQuery("#" + this.formId + "_ZIP").val(zip);

                jQuery("#" + this.formId + "_Country").val(country_iso);
                jQuery("#" + this.formId + "_Country").selectpicker('refresh');

                jQuery("#" + this.googlePlaceInputId).val(e.detail.selectedId);

                jQuery("#GooglePlaceCountryGooglePlaceIdGooglePlaceConfirmationEmailGooglePlaceDomain .description").addClass("d-none");
                jQuery("#" + this.formId + "_GooglePlaceDomain").val(domain);
                
                let confirmationEmailField = jQuery("#" + this.formId + "_GooglePlaceConfirmationEmail");
                let confirmationEmailFieldDescription = jQuery("#" + this.formId + "_GooglePlaceConfirmationEmail-help");

                confirmationEmailField.siblings(".input-group-append").find(".input-group-text").text("@" + domain);
                
                let splittedDesc = confirmationEmailFieldDescription.text().split(" (");
                let newDesc = splittedDesc[0] + " (" + domain + ")";
                confirmationEmailFieldDescription.text(newDesc);

                confirmationEmailField.closest(".form-group").removeClass("d-none");
                this.removeError();

                this.requestUpdate();
            }
        });
    }

    showError(message) {
        let autocomplete = this.shadowRoot.querySelector("autocomplete-input");
        autocomplete.setAttribute("message", message);
        autocomplete.clearInput();
    }

    removeError() {
        let autocomplete = this.shadowRoot.querySelector("autocomplete-input");
        autocomplete.setAttribute("message", "");
    }
}

customElements.define('google-places-input', GooglePlacesInput);