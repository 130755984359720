
import { LitElement, html, css } from 'lit-element';
import jQuery from 'jquery';

export class AutocompleteInput extends LitElement {

    static get properties() {
        return {
            inputId: { type: String },
            formMirrorInputId: { type: String },
            value: { type: String },
            icon: { type: String },
            placeholder: { type: String },
            readonly: { type: Boolean },
            label: {
                type: String,
                attribute: 'label',
                reflect: true
            },
            message: {
                type: String,
                attribute: 'message',
                reflect: true
            },
            description: {
                type: String,
                attribute: 'description',
                reflect: true
            },
            hasClearButton: { 
                type: Boolean,
                attribute: 'has-clear-button'
            },
            dataProviderUrl: { 
                type: String,
                attribute: 'data-provider-url'
            },
            poweredByGoogle: {
                type: Boolean,
                attribute: 'powered-by-google'
            },
            lastSelectionOnBlur: { 
                type: Boolean,
                attribute: 'last-selection-on-blur'
            }
        };
    }

    constructor() {
        super();

        this.inputId = "autocomplete-id";
        this.formMirrorInputId = false;
        this.value = "";
        this.icon = "";
        this.placeholder = "";
        this.label = "";
        this.message = "";
        this.description = "";
        this.selectedValue = "";
        this.hasClearButton = false;
        this.scheduleSearch = null;
        this.lastSearch = null;
        this.lastFoundSuggestions = [];
        this.suggestions = [];
        this.resultToIdConverter = i => "";
        this.resultToHtmlConverter = i => i;
        this.suggestionTitleSelector = false;
        this.queryParamProviders = new Map();
        this.lastSelectionOnBlur = false;
    }

    attributeChangedCallback(name, oldval, newval) {
        if (name === "value" && newval !== "") {
            this.selectedValue = newval;
        }
        super.attributeChangedCallback(name, oldval, newval);
    }

    clearInput(includingLastSuggestions) {
        includingLastSuggestions = includingLastSuggestions || false;
        jQuery(this.shadowRoot).find("input").val("");
        this.suggestions = [];
        if (includingLastSuggestions) {
            this.lastFoundSuggestions = [];
        }
        this.selectedValue = "";
        this.requestUpdate();
    }

    render() {
        return html`
            <link href="/fonts/lobooster/style.css" rel="stylesheet" />
            <link href="/node_modules/bootstrap/dist/css/bootstrap.min.css" rel="stylesheet" />
            <link href="/node_modules/open-iconic/font/css/open-iconic-bootstrap.css" rel="stylesheet" />

            <div class="${this.icon ? 'icon-input form-control' : 'form-group'}">
                ${this.icon ? html`<i class="${this.icon}"></i>` : ''}
                ${this.label ? html`<label for="${this.inputId}">${this.label}</label>` : ``}
                <div class="autocomplete">
                    <input type="text" tabindex="${this.readonly ? "-1" : "0"}" id="${this.inputId}" value="${this.value}" .placeholder="${this.placeholder}" class="form-control${this.message ? " is-invalid" : ""}${this.readonly ? " form-control-plaintext" : ""}" @blur="${this.handleBlur}" @focus="${this.handleFocus}" @keydown="${this.handleKeyDown}" @keyup="${this.handleKeyUp}" />
                    ${this.hasClearButton && this.selectedValue && !this.readonly ? html`<a href="#" class="clear-input" @click="${this.handleClearButtonClick}"><span class="oi oi-x"></span></a>` : ``}
                    ${this.suggestions.length > 0 ?
                html`
                    <ul class="autosuggest ${this.poweredByGoogle ? 'powered-by-google' : ''}">
                        ${this.suggestions.map(i => html`
                        <li data-id="${this.resultToIdConverter(i)}" @click="${this.handleItemClick}">${this.resultToHtmlConverter(i)}</li>
                        `)}
                    </ul>` : ``}
                    ${this.description ?
                html`<small id="${this.inputId}-help" class="form-text text-muted">${this.description}</small>` : ``}
                    ${this.message ?
                html`<div class="invalid-feedback">${this.message}</div>` : ``}
                </div>
            </div>`;
    }
    
    setResultToIdConverter(converter) {
        this.resultToIdConverter = converter;
    }

    setResultToHtmlConverter(converter) {
        this.resultToHtmlConverter = converter;
    }

    setSuggestionTitleSelector(selector) {
        this.suggestionTitleSelector = selector;
    }

    addQueryParamProvider(paramName, valueProvider) {
        this.queryParamProviders.set(paramName, valueProvider);
    }

    fireEvent(name, detail) {
        let event = new CustomEvent(name, {
            detail: detail
        });
        this.dispatchEvent(event);
    }

    handleClearButtonClick(e) {
        e.preventDefault();
        this.clearInput();
        this.fireEvent("clear-input", null);

        if (this.formMirrorInputId) {
            jQuery("#" + this.formMirrorInputId).val("");
        }
    }

    handleItemClick(e) {
        let value = this.suggestionTitleSelector ? jQuery(e.target).find(this.suggestionTitleSelector).text() : jQuery(e.target).text();
        this.selectedValue = value;
        jQuery(this.shadowRoot).find("input").val(value);
        this.fireEvent("suggestion-select", {
            selectedId: jQuery(e.target).attr("data-id")
        });

        if (this.formMirrorInputId) {
            jQuery("#" + this.formMirrorInputId).val(this.selectedValue);
        }

        this.requestUpdate();
    }

    handleFocus(e) {
        this.suggestions = this.lastFoundSuggestions;
        this.requestUpdate();
    }

    handleBlur(e) {
        setTimeout(() => {
            if (this.lastSelectionOnBlur) {
                jQuery(this.shadowRoot).find("input").val(this.selectedValue);

                if (this.formMirrorInputId) {
                    jQuery("#" + this.formMirrorInputId).val(this.searchValue);
                }
            }
            else {
                this.selectedValue = this.shadowRoot.querySelector("input").value;
            }

            if (this.suggestions.length > 0) {
                this.lastFoundSuggestions = this.suggestions;
                this.suggestions = [];
                this.requestUpdate();
            }
        }, 300);
    }

    handleKeyDown(e) {
        if (e.key === "Escape") {
            this.lastFoundSuggestions = this.suggestions;
            this.suggestions = [];
            this.requestUpdate();
        }
    }

    handleKeyUp(e) {
        if (e.key === "Tab" || e.key === "Shift") {
            return;
        }

        let searchValue = e.target.value;

        if (searchValue === this.lastSearch) {
            return;
        }

        if (this.formMirrorInputId) {
            jQuery("#" + this.formMirrorInputId).val(searchValue);
        }

        clearTimeout(this.scheduleSearch);

        if (searchValue === "") {
            return;
        }

        this.scheduleSearch = setTimeout(() => {
            this.performSearch(searchValue);
        }, 500);
    }

    performSearch(searchValue) {
        let data = {};
        data.input = searchValue;
        if (this.queryParamProviders.size > 0) {
            for (let [paramName, provider] of this.queryParamProviders) {
                data[paramName] = provider();
            }
        }

        jQuery.ajax({
            method: "GET",
            dataType: "json",
            url: this.dataProviderUrl,
            data: data,
            success: result => {
                if (result.status !== "OK") {
                    this.suggestions = [];
                    return;
                }

                this.lastSearch = searchValue;
                this.suggestions = result.predictions;
                this.requestUpdate();
            }
        });
    }

    static get styles() {
        return css`
            .autocomplete {
                position: relative;
            }

            .autocomplete .clear-input {
                display: block;
                position: absolute;
                right: 0;
                top: 0px;
                background: none;
                border: none;
                background: none;
                height: 35px;
                line-height: 35px;
                padding: 0 10px;
                color: #b8b8b8;
                outline: none;
            }

            .autocomplete .autosuggest {
                list-style: none;
                padding: 0 10px 0px 10px;
                position: absolute;
                background: rgb(var(--white));
                width: 100%;
                left: 0;
                margin-top: 1px;
                z-index: 10;
                border: 1px solid #b8b8b8;
                overflow-y: auto;
            }

            .autocomplete .autosuggest.powered-by-google {
                background: rgb(var(--white)) var(--google-logo) no-repeat right 10px bottom 5px;
                background-size: 72px 9px;
                padding-bottom: 19px;
            }

            .autocomplete .autosuggest li {
                display: flex;
                flex-direction: column;
                cursor: pointer;
                padding: 10px 0;
                position: relative;
                margin-top: -1px;
            }

            .autocomplete .autosuggest li:not(:last-child) {
                border-bottom: 1px solid #b8b8b8;
            }

            .autocomplete .autosuggest.powered-by-google li:last-child {
                border-bottom: 1px solid #b8b8b8;
            }

            .autocomplete .autosuggest li:hover {
                width: calc(100% + 20px);
                margin-left: -10px;
                padding: 10px;
                background: rgb(var(--orange));
                color: rgb(var(--white));
                border-bottom: 1px solid transparent;
            }

            .autocomplete .autosuggest li:hover .address {
                color: rgb(var(--white));
            }

            .autocomplete .autosuggest li span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                pointer-events: none;
            }

            .autocomplete .autosuggest li span.title {
                font-weight: bold;
                margin-bottom: 2px;
            }

            .autocomplete .autosuggest li span.address {
                color: #b8b8b8;
            }

            .form-control {
                background: rgb(var(--white));
                border: 1px solid var(--gray);
                color: rgb(var(--black));
                font-size: 14px;
            }

            .form-control:focus, .form-control.focus, .form-control.focused {
                background: rgb(var(--white));
                color: rgb(var(--black));
                border: 1px solid rgb(var(--orange));
                box-shadow: 0px 0px 2px 2px var(--orange-lighter);
            }

            .icon-input {
                position: relative;
                border: none;
                width: 100%;
                min-width: 200px;
                margin-bottom: 10px;
                border: 1px solid var(--gray);
            }
            
            .icon-input i {
                color: rgb(var(--orange));
                vertical-align: middle;
                position: relative;
                z-index: 1;
                pointer-events: none;
            }
            
            .icon-input .autocomplete {
                margin: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100% !important;
                height: 100%;
            }
            
            .icon-input input {
                    margin: 0;
                    background: rgb(var(--white));
                    color: rgb(var(--black));
                    border: none;
                    padding-left: 40px;
                    width: 100% !important;
                    height: 100%;
                    outline: none;
                    border-radius: 0.25rem;
                    border: 1px solid rgb(var(--white));
            }

            .icon-input input:focus {
                background: rgb(var(--white));
                color: rgb(var(--black));
            }

            .form-control-plaintext {
                background: transparent !important;
                padding-left: 0 !important;
                color: #b8b8b8 !important;
                border: 1px solid transparent !important;
                pointer-events: none;
        
                .dropdown-toggle {
                    border: none !important;
                    padding-left: 0 !important;
                    color: #b8b8b8 !important;
                    &:after {
                        border: none;
                    }
                }
        
                &:focus, &.focus, &.focused {
                    box-shadow: none !important;
                }
            }
        `;
    }
}

customElements.define('autocomplete-input', AutocompleteInput);